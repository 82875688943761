<script lang="jsx">
import TojoyBreadcrumb from '@/components/common/breadcrumb'
export default {
    components: {
        TojoyBreadcrumb
    },
    data() {
        return {
            breadcrumbList: [
                {
                    title: '首页',
                    route: 'Home'
                },
                {
                    title: '个人中心',
                    route: 'infoleftbar-material'
                }
            ]
        }
    },
    render(h) {
        return (
            <div class="navigation-info">
                <tojoy-breadcrumb ref="tojoyBreadcrumb" breadcrumb-data={this.breadcrumbList}/>
                <div class="routernav">
                    <router-view />
                </div>
            </div>

        )
    },
}
</script>
<style lang="scss" scoped>
.navigation-info {
  display: inline-block;
  height: 100%;
  width: 100%;
  .routernav {
    height: calc(100% - 47px);
  }
}
</style>
